<template>
  <div class="activites">
    <div class="pt-2">
      <carousel
        class="carousel"
        :nav="false"
        :responsive="{ 0: { items: 1 }, 600: { items: 3 } }"
        v-if="websiteActivitiesList.length && isRun"
      >
        <div
          @click="setActiveActivity(activity.id)"
          class="activities-content-card"
          :class="{ active: activity.id == id }"
          v-for="(activity, index) in websiteActivitiesList"
          :key="index"
        >
          <div class="title p-1 text-center">
            <span>{{ activity.title }}</span>
          </div>
          <img
            :src="
              activity.imageDto.path.replace(
                '~',
                $store.getters['app/domainHost']
              )
            "
          />
        </div>
      </carousel>
    </div>
  </div>
</template>

<script>
  import carousel from "vue-owl-carousel";
  import { mapGetters } from "vuex";
  export default {
    components: {
      carousel,
    },
    props: {
      id: String,
      isRun: Boolean,
    },
    computed: {
      ...mapGetters(["websiteActivitiesList"]),
    },
    methods: {
      setActiveActivity(id) {
        this.$router.push("/activities/" + id);
      },
    },
  };
</script>
<style lang="scss" scoped>
  @import "src/assets/scss/variables/variables.scss";
  .activites {
    display: flex;
    flex-direction: column;
    justify-content: center;
    .activities-content-card {
      cursor: pointer;
      height: 200px;
      width: 220px;
      margin: 40px auto;
      position: relative;
      transition: all 0.4s linear;
      &:after,
      &:before {
        content: "";
        width: 100%;
        height: 4px;
        background: $secondaryPrimary;
        position: absolute;
        transition: all 0.4s linear;
      }
      &:after {
        top: -6px;
        right: 0;
      }
      &:before {
        bottom: -6px;
        left: 0;
      }
      img {
        width: 100%;
      }
      .title {
        position: absolute;
        top: 0;
        left: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0);
        transition: all 0.4s linear;
        color: #fff;
        span {
          transition: all 0.4s linear;
          transform: scale(0);
        }
      }
      &.active,
      &:hover {
        transform: scale(1.4);
        &:after,
        &:before {
          width: 50%;
        }
        .title {
          background: rgba(0, 0, 0, 0.5);
          span {
            transform: scale(1);
          }
        }
      }
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
  [dir="rtl"] {
    .carousel {
      direction: ltr;
    }
  }
</style>
