import { render, staticRenderFns } from "./main-section-activities.vue?vue&type=template&id=358e6014&scoped=true&"
import script from "./main-section-activities.vue?vue&type=script&lang=js&"
export * from "./main-section-activities.vue?vue&type=script&lang=js&"
import style0 from "./main-section-activities.vue?vue&type=style&index=0&id=358e6014&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "358e6014",
  null
  
)

export default component.exports